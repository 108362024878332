<template>
  <el-popover placement="bottom" trigger="hover" width="194">
    <template #reference>
      <el-button class="m-2" type="primary" size="mini">
        {{ activeName }}
        <el-icon class="el-icon-arrow-down"></el-icon>
      </el-button>
    </template>

    <div class="base-map-toggle-main">
      <div class="base-map-toggle-title">切换地图类型</div>
      <div
        v-for="item in baseMapLayers"
        :key="item.value"
        class="base-map-toggle-item"
        @click="toggleHandle(item)"
      >
        <img
          :src="item.pathActive"
          v-if="item.value === active"
          alt=""
          srcset=""
          class="base-map-toggle-img"
        />
        <img :src="item.path" v-else alt="" srcset="" class="base-map-toggle-img" />
      </div>
    </div>
  </el-popover>
</template>
<script>
import { reactive, ref } from 'vue'
import vecBase from '@assets/image/map/base-map-vec.png'
import imgBase from '@assets/image/map/base-map-img.png'
import vecBaseActive from '@assets/image/map/base-map-vec-active.png'
import imgBaseActive from '@assets/image/map/base-map-img-active.png'
export default {
  name: 'baseMapToggle',
  props: {
    map: {
      type: Object,
      required: true
    }
  },
  emits: ['toggle'],

  setup(props, context) {
    const baseMapLayers = reactive([
      {
        name: '矢量图',
        value: 'vec',
        path: vecBase,
        pathActive: vecBaseActive
      },
      {
        name: '影像图',
        value: 'img',
        path: imgBase,
        pathActive: imgBaseActive
      }
    ])
    const active = ref('vec')
    const activeName = ref('矢量图')

    const toggleHandle = item => {
      active.value = item.value
      activeName.value = item.name
      context.emit('toggle', item.value)
    }

    return {
      baseMapLayers,
      vecBase,
      imgBase,
      vecBaseActive,
      imgBaseActive,
      active,
      toggleHandle,
      activeName
    }
  }
}
</script>
<style lang="scss" scoped>
.el-icon-arrow-down {
  width: 16px;
}
.base-map-toggle-title {
  background: #f0f0f0;
  height: 32px;
  line-height: 32px;
  padding-left: 16px;
  color: #666;
  margin-bottom: 12px;
}
.base-map-toggle-img {
  width: 80px;
  height: 32px;
}
.base-map-toggle-item {
  width: 80px;
  height: 32px;
  margin-right: 8px;
  margin-bottom: 8px;
  float: left;
  cursor: pointer;
}
.base-map-toggle-item:nth-child(2n + 1) {
  margin-right: 0;
}
.base-map-toggle-main::after {
  content: '';
  display: block;
  clear: both;
}
</style>